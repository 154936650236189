import React, { Fragment, useEffect, useState } from 'react';
import { CardBody, Col, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  Column,
  Table as ReactTable,
  ColumnFiltersState,
  FilterFn,
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

import { rankItem } from '@tanstack/match-sorter-utils';

// Column Filter
const Filter = ({ column }: { column: Column<any, unknown>; table: ReactTable<any> }) => {
  const columnFilterValue = column.getFilterValue();

  return (
    <>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? '') as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder="Search..."
        className="w-36 border shadow rounded"
        list={column.id + 'list'}
      />
      <div className="h-1" />
    </>
  );
};

// Global Filter
const DebouncedInput = ({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return <input {...props} value={value} id="search-bar-0" className="form-control border-0 search" onChange={(e) => setValue(e.target.value)} />;
};

type TableContainerProps = {
  columns: any[];
  data: any[];
  isGlobalFilter?: boolean;
  customPageSize?: number;
  tableClass?: string;
  theadClass?: string;
  trClass?: string;
  thClass?: string;
  divClass?: string;
  SearchPlaceholder?: string;
  page?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
  hasPagination?: boolean;
  serverSidePagination?: boolean;
};

const TableContainer = ({
  columns,
  data,
  isGlobalFilter,
  customPageSize = 15,
  tableClass,
  theadClass,
  trClass = '',
  thClass = '',
  divClass = '',
  SearchPlaceholder,
  page = 1,
  totalPages = 1,
  onPageChange,
  hasPagination,
  serverSidePagination,
}: TableContainerProps) => {
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
    const itemRank = rankItem(row.getValue(columnId), value);
    addMeta({
      itemRank,
    });
    return itemRank.passed;
  };

  const table = useReactTable({
    columns,
    data,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    manualPagination: serverSidePagination,
    pageCount: totalPages,
  });

  const { getHeaderGroups, getRowModel, setPageSize } = table;

  useEffect(() => {
    Number(customPageSize) && setPageSize(Number(customPageSize));
  }, [customPageSize, setPageSize]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const showEllipsis = totalPages > 5;
    const currentPage = page;

    // Botão First
    pageNumbers.push(
      <li key="first" className={page <= 1 ? 'page-item disabled' : 'page-item'}>
        <Link
          to="#"
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            onPageChange?.(1);
          }}
        >
          First
        </Link>
      </li>,
    );

    // Botão Previous
    pageNumbers.push(
      <li key="prev" className={page <= 1 ? 'page-item disabled' : 'page-item'}>
        <Link
          to="#"
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            if (page > 1) onPageChange?.(page - 1);
          }}
        >
          Previous
        </Link>
      </li>,
    );

    // Primeiros números
    if (showEllipsis) {
      // Sempre mostra página 1 e 2
      [1, 2].forEach((num) => {
        pageNumbers.push(
          <li key={num} className={`page-item ${num === currentPage ? 'active' : ''}`}>
            <Link
              to="#"
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                onPageChange?.(num);
              }}
            >
              {num}
            </Link>
          </li>,
        );
      });

      // Adiciona ellipsis se necessário
      if (currentPage > 3) {
        pageNumbers.push(
          <li key="ellipsis1" className="page-item disabled">
            <span className="page-link">...</span>
          </li>,
        );
      }

      // Páginas ao redor da página atual
      if (currentPage > 2 && currentPage < totalPages - 1) {
        pageNumbers.push(
          <li key={currentPage} className="page-item active">
            <Link
              to="#"
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                onPageChange?.(currentPage);
              }}
            >
              {currentPage}
            </Link>
          </li>,
        );
      }

      // Adiciona ellipsis se necessário
      if (currentPage < totalPages - 2) {
        pageNumbers.push(
          <li key="ellipsis2" className="page-item disabled">
            <span className="page-link">...</span>
          </li>,
        );
      }

      // Sempre mostra as duas últimas páginas
      [totalPages - 1, totalPages].forEach((num) => {
        if (num > 2) {
          pageNumbers.push(
            <li key={num} className={`page-item ${num === currentPage ? 'active' : ''}`}>
              <Link
                to="#"
                className="page-link"
                onClick={(e) => {
                  e.preventDefault();
                  onPageChange?.(num);
                }}
              >
                {num}
              </Link>
            </li>,
          );
        }
      });
    } else {
      // Se houver poucas páginas, mostra todas
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <Link
              to="#"
              className="page-link"
              onClick={(e) => {
                e.preventDefault();
                onPageChange?.(i);
              }}
            >
              {i}
            </Link>
          </li>,
        );
      }
    }

    // Botão Next
    pageNumbers.push(
      <li key="next" className={page >= totalPages ? 'page-item disabled' : 'page-item'}>
        <Link
          to="#"
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            if (page < totalPages) onPageChange?.(page + 1);
          }}
        >
          Next
        </Link>
      </li>,
    );

    // Botão Last
    pageNumbers.push(
      <li key="last" className={page >= totalPages ? 'page-item disabled' : 'page-item'}>
        <Link
          to="#"
          className="page-link"
          onClick={(e) => {
            e.preventDefault();
            onPageChange?.(totalPages);
          }}
        >
          Last
        </Link>
      </li>,
    );

    return pageNumbers;
  };

  return (
    <Fragment>
      {isGlobalFilter && (
        <Row className="mb-3">
          <CardBody className="border border-dashed border-end-0 border-start-0">
            <form>
              <Row>
                <Col sm={5}>
                  <div className="search-box me-2 mb-2 d-inline-block col-12">
                    <DebouncedInput value={globalFilter ?? ''} onChange={(value) => setGlobalFilter(String(value))} placeholder={SearchPlaceholder} />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                </Col>
              </Row>
            </form>
          </CardBody>
        </Row>
      )}

      <div className={divClass}>
        <Table hover className={tableClass}>
          <thead className={theadClass}>
            {getHeaderGroups().map((headerGroup: any) => (
              <tr className={trClass} key={headerGroup.id}>
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    className={thClass}
                    {...{
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <React.Fragment>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: ' ',
                          desc: ' ',
                        }[header.column.getIsSorted() as string] ?? null}
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter column={header.column} table={table} />
                          </div>
                        ) : null}
                      </React.Fragment>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {getRowModel().rows.map((row: any) => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell: any) => {
                    return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>

      {hasPagination && (
        <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
          <div className="col-sm">
            <div className="text-muted">
              Showing<span className="fw-semibold ms-1">{customPageSize}</span> of <span className="fw-semibold">{serverSidePagination ? customPageSize * totalPages : data.length}</span> Results
            </div>
          </div>
          <div className="col-sm-auto">
            <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">{renderPageNumbers()}</ul>
          </div>
        </Row>
      )}
    </Fragment>
  );
};

export default TableContainer;
