import React, { useMemo } from 'react';
import TableContainer from '../../Components/Common/TableContainerReactTable';

type TransactionData = {
  magicNumber: string;
  contracts: number;
  value: number;
  date: string;
};

type TransactionTableProps = {
  data: TransactionData[];
  pageSize: number;
  pageIndex: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const TransactionTable: React.FC<TransactionTableProps> = ({ data, pageSize, pageIndex, totalPages, onPageChange }) => {
  const columns = useMemo(
    () => [
      {
        header: 'Magic Number',
        accessorKey: 'magicNumber',
        enableColumnFilter: false,
      },
      {
        header: 'Contratos',
        accessorKey: 'contracts',
        enableColumnFilter: false,
      },
      {
        header: 'Resultado',
        accessorKey: 'value',
        cell: (cell: any) => {
          const value = cell.getValue();
          if (value === undefined || value === null) return '-';

          const colorClass = value > 0 ? 'text-success' : 'text-danger';
          return (
            <span className={`fw-bold ${colorClass}`}>
              {value.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </span>
          );
        },
        enableColumnFilter: false,
      },
      {
        header: 'Data',
        accessorKey: 'date',
        cell: (cell: any) => {
          const value = cell.getValue();
          if (!value) return '-';
          return new Date(value).toLocaleString('pt-BR');
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={data}
        customPageSize={pageSize}
        tableClass="table-centered align-middle table-nowrap mb-0"
        theadClass="text-muted table-light"
        SearchPlaceholder="Buscar transações..."
        page={pageIndex}
        totalPages={totalPages}
        onPageChange={onPageChange}
        hasPagination={true}
        serverSidePagination={true}
        divClass="table-responsive"
        trClass="text-nowrap"
        thClass="sort"
      />
    </React.Fragment>
  );
};

export { TransactionTable };
