import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import { getCapitalCurveDataByPortfolioIdData } from 'slices/thunks';
import Section from '../Section';
import { useParams } from 'react-router-dom';
import { initSelectedDates } from '..';
import RevenuePortfolio from './Revenue-Portfolio';
import { PaginationTablePortfolio } from './ReactTable';

const DashboardPortfolio = () => {
  const { id } = useParams();
  document.title = `Dashboard | QuantARM`;
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const [paginationData, setPaginationData] = useState([]);

  const selectDashboardPortfolioData = createSelector(
    (state: any) => state.DashboardPortfolio.historyPortfolioCurveData,
    (historyPortfolioCurveData: any) => ({
      historyPortfolioCurveData: historyPortfolioCurveData ? historyPortfolioCurveData : [],
    }),
  );

  const { historyPortfolioCurveData } = useSelector(selectDashboardPortfolioData);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  useEffect(() => {
    dispatch(getCapitalCurveDataByPortfolioIdData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts, portfolioId: Number(id) }));
  }, [dispatch, selectedDates, selectedAccounts, id]);

  useEffect(() => {
    if (historyPortfolioCurveData && historyPortfolioCurveData.length > 0) {
      const formattedData = historyPortfolioCurveData[0].data
        .map((value: number, index: number) => ({
          magicNumber: id,
          date: historyPortfolioCurveData[0].periods[index],
          valor: `R$ ${value.toFixed(2)}`,
          status: value > 0 ? 'Gain' : value < 0 ? 'Loss' : 'Zero',
        }))
        .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

      setPaginationData(formattedData);
    }
  }, [historyPortfolioCurveData, id]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} capitalCurveData={historyPortfolioCurveData || []} title="Dashboard do Portfólio" />
                <Row>
                  <Col xl={12}>
                    <RevenuePortfolio selectedDates={selectedDates} selectDashboardPortfolioData={historyPortfolioCurveData} portfolioId={Number(id)} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Operações diária</h5>
                      </CardHeader>
                      <CardBody>
                        <PaginationTablePortfolio data={paginationData} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardPortfolio;
