//REGISTER
export const POST_FAKE_REGISTER = '/auth/signup';

//LOGIN
export const POST_FAKE_LOGIN = '/auth/signin';
export const POST_FAKE_JWT_LOGIN = '/post-jwt-login';
export const POST_FAKE_PASSWORD_FORGET = '/auth/forgot-password';
export const POST_FAKE_JWT_PASSWORD_FORGET = '/jwt-forget-pwd';
export const SOCIAL_LOGIN = '/social-login';

//CREATE_USER
export const POST_CREATE_USER = '/users';

//PROFILE
export const POST_EDIT_JWT_PROFILE = '/post-jwt-profile';
export const POST_EDIT_PROFILE = '/user';

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = '/allRevenue-data';

// Accounts
export const GET_USER_ACCOUNTS_BY_UID = '/user-accounts';

//Magic
export const GET_LIST_MAGIC = '/magic/list-magic';
export const UPDATE_MAGIC = '/magic/update-magic';
export const CREATE_PORTFOLIO = '/portfolio';

// Monte Carlo
export const GET_LIST_MONTE_CARLO = '/monte-carlo/magic/list';
export const GET_LIST_PORTFOLIO_MONTE_CARLO = '/monte-carlo/portfolio/list';
export const GET_MONTE_CARLO_BY_MAGIC_NUMBER = '/monte-carlo';
export const CREATE_MONTE_CARLO = '/monte-carlo';

export const GET_SUMMARY_BY_DAY = '/deals/summary-by-day/';
export const GET_CAPITAL_CURVE = '/deals/capital-curve/';
export const GET_CAPITAL_BOT_CURVE = '/deals/capital-curve-by-magic-number/';
export const GET_CAPITAL_PORTFOLIO_CURVE = '/deals/capital-curve-by-portfolio-id/';
export const GET_SUMMARY_PORTFOLIO_BY_DAY = '/deals/summary-portfolios-by-day/';
export const GET_MONTHREVENUE_DATA = '/monthRevenue-data';
export const GET_HALFYEARREVENUE_DATA = '/halfYearRevenue-data';
export const GET_YEARREVENUE_DATA = '/yearRevenue-data';

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = '/all-data';
export const GET_HALFYEARLY_DATA = '/halfyearly-data';
export const GET_MONTHLY_DATA = '/monthly-data';

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = '/allAudiencesMetrics-data';
export const GET_MONTHLYAUDIENCESMETRICS_DATA = '/monthlyAudiencesMetrics-data';
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA = '/halfyearlyAudiencesMetrics-data';
export const GET_YEARLYAUDIENCESMETRICS_DATA = '/yearlyAudiencesMetrics-data';

// Users by Device
export const GET_TODAYDEVICE_DATA = '/todayDevice-data';
export const GET_LASTWEEKDEVICE_DATA = '/lastWeekDevice-data';
export const GET_LASTMONTHDEVICE_DATA = '/lastMonthDevice-data';
export const GET_CURRENTYEARDEVICE_DATA = '/currentYearDevice-data';

// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = '/todaySession-data';
export const GET_LASTWEEKSESSION_DATA = '/lastWeekSession-data';
export const GET_LASTMONTHSESSION_DATA = '/lastMonthSession-data';
export const GET_CURRENTYEARSESSION_DATA = '/currentYearSession-data';

export const GET_RECENT_TRANSACTIONS = '/deals/transactions/recent';
export const GET_TRANSACTIONS = '/deals/transactions';
