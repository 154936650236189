import { getTransactionsStart, getTransactionsSuccess, getTransactionsFail } from './reducer';
import { getRecentTransactionsData } from '../../helpers/fakebackend_helper';
import { getUidFromSessionStorage } from 'slices/thunks';

interface GetTransactionsParams {
  selectedDates: string[];
  selectedAccount: string | number;
  page: number;
  pageSize: number;
}

export const getTransactions = (params: GetTransactionsParams) => async (dispatch: any) => {
  try {
    dispatch(getTransactionsStart());

    const uid = getUidFromSessionStorage();
    if (!uid) return;

    console.log('Requesting with params:', {
      uid,
      accountId: params.selectedAccount,
      dates: params.selectedDates,
      page: params.page,
      pageSize: params.pageSize,
    });

    const response = await getRecentTransactionsData(uid, params.selectedAccount, params.selectedDates, params.page, params.pageSize);

    console.log('API Response:', response);
    dispatch(getTransactionsSuccess(response));
  } catch (error: any) {
    console.error('Error fetching transactions:', error);
    dispatch(getTransactionsFail(error));
  }
};
