import React, { useEffect, useState, useMemo } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { getTransactions } from '../../slices/transactions/thunk';
import Section from '../Dashboard/Section';
import { TransactionTable } from './ReactTable';
import { initSelectedDates } from '../Dashboard';

// Selectors base
const selectTransactionsState = (state: any) => state.Transactions?.transactions;
const selectLayoutState = (state: any) => state.Layout?.selectedAccounts;

// Selectors transformados
const transactionsSelector = createSelector([selectTransactionsState], (transactions) => {
  if (!transactions) return { transactions: [], totalPages: 0 };

  return {
    transactions:
      transactions.data?.map((t: any) => ({
        ...t,
        date: new Date(t.date).toISOString(), // Transformando a data
        value: Number(t.value), // Garantindo que value é número
      })) || [],
    totalPages: Math.ceil((transactions.total || 0) / (transactions.pageSize || 15)),
  };
});

const selectedAccountsSelector = createSelector([selectLayoutState], (accounts) => {
  if (!accounts) return [];

  // Se accounts for uma string (número da conta)
  if (typeof accounts === 'string') {
    return [{ id: accounts }];
  }

  // Se accounts for um objeto simples
  if (typeof accounts === 'object' && !Array.isArray(accounts)) {
    if (accounts.id) {
      return [accounts];
    }
    // Se for um objeto com números como chaves
    const accountNumber = Object.values(accounts).join('');
    return accountNumber ? [{ id: accountNumber }] : [];
  }

  return Array.isArray(accounts) ? accounts : [];
});

const Transactions = () => {
  document.title = 'Transações | QuantARM';
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 15;

  // Usando os selectors memorizados
  const { transactions, totalPages } = useSelector(transactionsSelector);
  const selectedAccounts = useSelector(selectedAccountsSelector);

  // Calcula o resultado do dia
  const capitalCurveData = useMemo(() => {
    if (!transactions.length) return [];

    const dailyResults = transactions.reduce((acc: any, transaction: any) => {
      const date = transaction.date.split(' ')[0];
      if (!acc[date]) {
        acc[date] = {
          date,
          value: 0,
          accumulated: 0,
        };
      }
      acc[date].value += transaction.value; // Mudado de result para value
      return acc;
    }, {});

    const sortedDates = Object.keys(dailyResults).sort();
    let accumulated = 0;
    const values = [];
    const accumulatedValues = [];

    for (const date of sortedDates) {
      accumulated += dailyResults[date].value;
      values.push(dailyResults[date].value);
      accumulatedValues.push(accumulated);
    }

    return [
      {
        data: values,
        periods: sortedDates,
        dataAccumulated: accumulatedValues,
      },
    ];
  }, [transactions]);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    setCurrentPage(1); // Reset para primeira página quando mudar as datas
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (selectedDates.length && selectedAccounts?.length > 0) {
      const account = selectedAccounts[0];

      // Se account for uma string direta
      if (typeof account === 'string') {
        dispatch(
          getTransactions({
            selectedDates,
            selectedAccount: account,
            page: currentPage,
            pageSize,
          }),
        );
        return;
      }

      // Se account for um objeto
      if (typeof account === 'object' && account !== null) {
        const accountId = account.id || (typeof account === 'object' ? Object.values(account).join('') : null);

        if (accountId) {
          console.log('Using account ID:', accountId);
          dispatch(
            getTransactions({
              selectedDates,
              selectedAccount: accountId,
              page: currentPage,
              pageSize,
            }),
          );
          return;
        }
      }

      console.error('Invalid account format:', { account, selectedAccounts });
    }
  }, [dispatch, selectedDates, selectedAccounts, currentPage]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} capitalCurveData={capitalCurveData} />
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        <h4 className="card-title mb-0">Lista de Transações</h4>
                      </CardHeader>
                      <CardBody>
                        <TransactionTable data={transactions} pageSize={pageSize} pageIndex={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Transactions;
