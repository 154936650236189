import { Col, Row } from 'reactstrap';
import Flatpickr from 'react-flatpickr';
import { Portuguese } from 'flatpickr/dist/l10n/pt';
import React from 'react';

type SectionProps = {
  selectedDates: string[];
  setSelectedDates: (dates: string[]) => void;
  capitalCurveData?: Array<{
    data: number[];
    periods: string[];
    dataAccumulated: number[];
  }>;
  title?: string;
};

const getUserNameFromSessionStorage = () => {
  const authUserString = sessionStorage.getItem('authUser');
  if (authUserString) {
    const authUser = JSON.parse(authUserString);
    return authUser.providerData[0].displayName || 'Admin';
  } else {
    throw new Error('authUser não encontrado no sessionStorage');
  }
};

const Section = ({ selectedDates, setSelectedDates, capitalCurveData = [], title = 'Bem vindo' }: SectionProps) => {
  const parseISODate = (dateString: string | undefined): Date | undefined => {
    if (!dateString) return undefined;

    // Se a data não tiver o formato ISO completo, adiciona o horário
    if (!dateString.includes('T')) {
      const date = new Date(dateString);
      date.setUTCHours(3, 0, 0, 0);
      return date;
    }
    return new Date(dateString);
  };

  const handleStartDateChange = (dates: Date[]) => {
    if (dates[0]) {
      const date = new Date(dates[0]);
      date.setUTCHours(3, 0, 0, 0);
      setSelectedDates([date.toISOString(), selectedDates[1] || '']);
    }
  };

  const handleEndDateChange = (dates: Date[]) => {
    if (dates[0]) {
      const date = new Date(dates[0]);
      date.setUTCHours(3, 0, 0, 0);
      setSelectedDates([selectedDates[0] || '', date.toISOString()]);
    }
  };

  const getLucroHoje = () => {
    if (!capitalCurveData || !Array.isArray(capitalCurveData) || capitalCurveData.length === 0) {
      return null;
    }

    const data = capitalCurveData[0];

    if (!data || !data.data || data.data.length === 0) {
      return null;
    }

    return data.data[data.data.length - 1];
  };

  const getLucroStyle = (lucro: number | null) => {
    if (lucro === null) return 'text-warning'; // Amarelo para sem operações
    if (lucro > 0) return 'text-success'; // Verde para positivo
    if (lucro < 0) return 'text-danger'; // Vermelho para negativo
    return 'text-warning'; // Amarelo para zero
  };

  const lucroHoje = getLucroHoje();
  const lucroStyle = getLucroStyle(lucroHoje);

  return (
    <React.Fragment>
      <Row className="mb-3 pb-1">
        <Col xs={12}>
          <div className="d-flex align-items-lg-center flex-lg-row flex-column">
            <div className="me-auto">
              <h4 className="fs-16 mb-1">
                {title}, {getUserNameFromSessionStorage()}!
              </h4>
            </div>

            <div className="d-flex align-items-center gap-4">
              <p className="mb-0 fs-16 fw-bold">
                <span className="text-white">Resultado do Dia: </span>
                <span className={lucroStyle}>
                  {(lucroHoje ?? 0).toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </span>
              </p>

              <div className="mt-3 mt-lg-0">
                <form action="#">
                  <Row className="g-3 mb-0 align-items-center">
                    <div className="col-sm-auto">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                            dateFormat: 'd/m/Y',
                            defaultDate: selectedDates[0] ? parseISODate(selectedDates[0]) : undefined,
                            onChange: handleStartDateChange,
                            locale: Portuguese,
                          }}
                          placeholder="Data inicial"
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                          <i className="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="input-group">
                        <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                            dateFormat: 'd/m/Y',
                            defaultDate: selectedDates[1] ? parseISODate(selectedDates[1]) : undefined,
                            onChange: handleEndDateChange,
                            locale: Portuguese,
                          }}
                          placeholder="Data final"
                        />
                        <div className="input-group-text bg-primary border-primary text-white">
                          <i className="ri-calendar-2-line"></i>
                        </div>
                      </div>
                    </div>
                  </Row>
                </form>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default Section;
