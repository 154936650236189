import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import RevenueBot from './Revenue-Bot';
import { useSelector } from 'react-redux';
import { getCapitalCurveDataByMagicNumberData } from 'slices/thunks';
import Section from '../Section';
import { useParams } from 'react-router-dom';
import { PaginationTableBot } from './ReactTable';
import { initSelectedDates } from '..';

const DashboardBot = () => {
  const { id } = useParams();
  document.title = `Dashboard | QuantARM`;
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const [paginationData, setPaginationData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 7;

  const selectDashboardBotData = createSelector(
    (state: any) => state.DashboardBot.historyBotCurveData,
    (historyBotCurveData: any) => ({
      historyBotCurveData: historyBotCurveData ? historyBotCurveData : [],
    }),
  );

  const { historyBotCurveData } = useSelector(selectDashboardBotData);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  useEffect(() => {
    const fetchData = () => {
      if (selectedAccounts && selectedAccounts.length > 0) {
        dispatch(
          getCapitalCurveDataByMagicNumberData({
            period: 'daily',
            selectedDates,
            selectedAccount: selectedAccounts,
            roboId: Number(id),
            page: currentPage,
            pageSize,
          }),
        );
      }
    };

    fetchData();
  }, [dispatch, selectedDates, selectedAccounts, id, currentPage]);

  useEffect(() => {
    if (historyBotCurveData?.data?.[0]) {
      const { data, periods } = historyBotCurveData.data[0];
      const formattedData = data
        .map((value: number, index: number) => ({
          magicNumber: id,
          date: periods[index],
          valor: `R$ ${value.toFixed(2)}`,
          status: value > 0 ? 'Gain' : value < 0 ? 'Loss' : 'Zero',
        }))
        .sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

      setPaginationData(formattedData);
      setTotalPages(historyBotCurveData.totalPages);
    } else {
      setPaginationData([]);
      setTotalPages(1);
    }
  }, [historyBotCurveData, id]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} capitalCurveData={historyBotCurveData} title="Dashboard do Robô" />
                <Row>
                  <Col xl={12}>
                    <RevenueBot selectedDates={selectedDates} selectDashboardBotData={historyBotCurveData} robotId={Number(id)} />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <Card>
                      <CardHeader>
                        <h5 className="card-title mb-0">Operações diária</h5>
                      </CardHeader>
                      <CardBody>
                        <PaginationTableBot data={paginationData} pageSize={pageSize} pageIndex={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DashboardBot;
