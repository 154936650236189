import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: {
    data: [],
    total: 0,
    page: 1,
    pageSize: 15,
    totalPages: 0,
  },
  loading: false,
  error: null,
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    getTransactionsSuccess(state, action) {
      console.log('API Response:', action.payload);
      state.transactions = {
        data: action.payload.data || [],
        total: action.payload.total || 0,
        page: action.payload.page || 1,
        pageSize: action.payload.pageSize || 15,
        totalPages: action.payload.totalPages || 0,
      };
      state.loading = false;
      state.error = null;
    },
    getTransactionsFail(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getTransactionsStart(state) {
      state.loading = true;
    },
  },
});

export const { getTransactionsSuccess, getTransactionsFail, getTransactionsStart } = transactionsSlice.actions;

export default transactionsSlice.reducer;
