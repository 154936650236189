import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

type Transaction = {
  magicNumber: string;
  contracts: number;
  value: number;
  date: string;
};

type RecentTransactionsProps = {
  transactions?: {
    data: Transaction[];
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
};

const RecentTransactions = ({ transactions }: RecentTransactionsProps) => {
  const transactionData = transactions?.data || [];

  return (
    <React.Fragment>
      <Card className="h-100">
        <CardHeader>
          <h5 className="card-title mb-0">Operações Recentes</h5>
        </CardHeader>
        <CardBody className="d-flex flex-column" style={{ overflowY: 'auto' }}>
          {transactionData.map((transaction, index) => (
            <div key={index}>
              <div className="transaction-item">
                {/* Linha 1: Magic Number, Seta de Status e Valor */}
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <div className="d-flex align-items-center">
                    <i className={`ri-arrow-${transaction.value > 0 ? 'up' : 'down'}-line text-${transaction.value > 0 ? 'success' : 'danger'} me-2`}></i>
                    <span className="fw-medium">Magic: {transaction.magicNumber}</span>
                  </div>
                  <span className={`text-${transaction.value > 0 ? 'success' : 'danger'}`}>
                    {transaction.value > 0 ? '+' : ''}
                    {transaction.value.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </div>

                {/* Linha 2: Contratos e Data */}
                <div className="d-flex justify-content-between text-muted small">
                  <span>
                    <i className="ri-file-list-line me-1"></i>
                    Contratos: {transaction.contracts}
                  </span>
                  <span>
                    <i className="ri-time-line me-1"></i>
                    {new Date(transaction.date).toLocaleString('pt-BR')}
                  </span>
                </div>
              </div>
              {index < transactionData.length - 1 && <hr className="my-2" />}
            </div>
          ))}
          <div className="mt-3">
            <Link to="/transactions" className="btn btn-soft-info w-100">
              Ver Todas as Transações <i className="ri-arrow-right-line align-bottom"></i>
            </Link>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentTransactions;
