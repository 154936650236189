import React, { useMemo } from 'react';
import TableContainer from '../../../Components/Common/TableContainerReactTable';

type PaginationData = {
  magicNumber: string;
  date: string;
  valor: string;
  status: string;
};

type PaginationTableBotProps = {
  data: PaginationData[];
  pageSize?: number;
  pageIndex?: number;
  totalPages?: number;
  onPageChange?: (page: number) => void;
};

const PaginationTableBot: React.FC<PaginationTableBotProps> = ({ data, pageSize = 10, pageIndex = 1, totalPages = 1, onPageChange }) => {
  const columns = useMemo(
    () => [
      {
        header: 'Magic Number',
        accessorKey: 'magicNumber',
        enableColumnFilter: false,
      },
      {
        header: 'Date',
        accessorKey: 'date',
        enableColumnFilter: false,
      },
      {
        header: 'Valor',
        accessorKey: 'valor',
        cell: (cell: any) => {
          const value = parseFloat(cell.getValue().replace('R$', '').replace(',', '.'));
          const colorClass = value > 0 ? 'text-success' : value < 0 ? 'text-danger' : 'text-warning';
          return <span className={`fw-bold ${colorClass}`}>{cell.getValue()}</span>;
        },
        enableColumnFilter: false,
      },
      {
        header: 'Status',
        accessorKey: 'status',
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case 'Gain':
              return <span className="badge bg-success-subtle text-success text-uppercase">{cell.getValue()}</span>;
            case 'Zero':
              return <span className="badge bg-warning-subtle text-warning text-uppercase">{cell.getValue()}</span>;
            default:
              return <span className="badge bg-danger-subtle text-danger text-uppercase">{cell.getValue()}</span>;
          }
        },
        enableColumnFilter: false,
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      <TableContainer
        columns={columns || []}
        data={data || []}
        customPageSize={pageSize}
        tableClass="table-centered align-middle table-nowrap mb-0"
        theadClass="text-muted table-light"
        SearchPlaceholder="Search Products..."
        page={pageIndex}
        totalPages={totalPages}
        onPageChange={onPageChange}
        hasPagination={true}
        serverSidePagination={true}
      />
    </React.Fragment>
  );
};

export { PaginationTableBot };
