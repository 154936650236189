import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import BotList from './dashboard-bot-list';
import Revenue from './Revenue';
import BotPortfolioList from './dashboard-bot-portfolio';
import Section from './Section';
import { getCapitalCurveData, getRevenueChartsData, getSummaryPortfolioByDayData, getRecentTransactions } from 'slices/thunks';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import RecentTransactions from './RecentTransactions';

export const formatDate = (date: Date) => {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
};

export const getFirstDayOfMonth = (currentDate: Date) => {
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getNextBusinessDay = (currentDate: Date) => {
  const dayOfWeek = currentDate.getDay();
  let daysToAdd = 1;
  if (dayOfWeek === 5) {
    daysToAdd = 3;
  } else if (dayOfWeek === 6) {
    daysToAdd = 2;
  }
  return new Date(currentDate.getTime() + daysToAdd * 86400000);
};

export const initSelectedDates = () => {
  const storedDates = sessionStorage.getItem('selectedDates');
  if (storedDates) {
    return JSON.parse(storedDates);
  }

  const today = new Date();
  const firstDayOfMonth = getFirstDayOfMonth(today);
  const nextBusinessDay = getNextBusinessDay(today);

  // Ajustando para UTC-3
  firstDayOfMonth.setUTCHours(3, 0, 0, 0);
  nextBusinessDay.setUTCHours(3, 0, 0, 0);

  const initialDates = [firstDayOfMonth.toISOString(), nextBusinessDay.toISOString()];

  sessionStorage.setItem('selectedDates', JSON.stringify(initialDates));
  return initialDates;
};

const Dashboard = () => {
  document.title = 'Dashboard | QuantARM';
  const dispatch: any = useDispatch();

  const [selectedDates, setSelectedDates] = useState(initSelectedDates());

  const updateSelectedDates = (dates: string[]) => {
    setSelectedDates(dates);
    sessionStorage.setItem('selectedDates', JSON.stringify(dates));
  };

  const selectDashboardData = createSelector(
    (state: any) => state.Dashboard.revenueData,
    (state: any) => state.Dashboard.capitalCurveData,
    (state: any) => state.Dashboard.recentTransactions,
    (revenueData, capitalCurveData, recentTransactions) => ({
      revenueData: revenueData ? revenueData.data : [],
      categories: revenueData ? revenueData.categories : [],
      capitalCurveData: capitalCurveData ? capitalCurveData : [],
      recentTransactions: recentTransactions || [],
    }),
  );

  const { revenueData, capitalCurveData, recentTransactions } = useSelector(selectDashboardData);

  const portfolioListData = useSelector((state: any) => state.Dashboard.portfolioListData);

  const selectedAccounts: any = useSelector((state: any) => state.Layout.selectedAccounts || []);

  useEffect(() => {
    dispatch(getRevenueChartsData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts }));
    dispatch(getSummaryPortfolioByDayData({ selectedDates, selectedAccount: selectedAccounts }));
    dispatch(getCapitalCurveData({ period: 'daily', selectedDates, selectedAccount: selectedAccounts }));
    dispatch(
      getRecentTransactions({
        selectedDates,
        selectedAccount: selectedAccounts,
      }),
    );
  }, [dispatch, selectedDates, selectedAccounts]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section selectedDates={selectedDates} setSelectedDates={updateSelectedDates} capitalCurveData={capitalCurveData} />

                {/* Gráfico e Transações Recentes */}
                <Row className="mb-3">
                  {' '}
                  {/* Adicionando margem bottom */}
                  <Col xl={9}>
                    <Revenue selectedDates={selectedDates} selectedCurveCapitals={capitalCurveData} />
                  </Col>
                  <Col xl={3}>
                    <RecentTransactions transactions={recentTransactions} />
                  </Col>
                </Row>

                {/* Lista de Portfólios em uma nova Row */}
                <Row>
                  <Col>
                    <BotPortfolioList selectedRevenueData={portfolioListData} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <BotList selectedRevenueData={revenueData} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
