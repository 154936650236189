import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { RevenueCharts } from './Dashboard';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import { useDispatch } from 'react-redux';
import { getCapitalCurveData } from '../../slices/thunks';
import { useSelector } from 'react-redux';

type RevenueProps = {
  selectedDates: string[];
  selectedCurveCapitals: any;
};

const Revenue = ({ selectedDates, selectedCurveCapitals }: RevenueProps) => {
  const { t } = useTranslation();
  const dispatch: any = useDispatch();

  const [chartData, setChartData] = useState<any>([]);
  const [chartColors, setChartColors] = useState<string[]>([]);
  const [period, setPeriod] = useState<string>('daily');

  const selectedAccounts = useSelector((state: any) => state.Layout.selectedAccounts || []);

  const generateRandomColor = () => {
    const colorNumber = Math.floor(Math.random() * 16777215);
    const colorHex = colorNumber.toString(16).padStart(6, '0');
    return '#' + colorHex;
  };

  const onChangeChartPeriod = (pType: string) => {
    setPeriod(pType);
    dispatch(getCapitalCurveData({ period: pType, selectedDates: selectedDates, selectedAccount: selectedAccounts }));
  };

  useEffect(() => {
    if (!selectedCurveCapitals || !Array.isArray(selectedCurveCapitals) || selectedCurveCapitals.length === 0) {
      return;
    }

    let filteredData = selectedCurveCapitals.map((capitalCurve) => ({
      name: '',
      type: capitalCurve.type,
      data: capitalCurve.dataAccumulated,
      dataAccumulated: capitalCurve.data,
      periods: capitalCurve.periods,
    }));

    setChartData(filteredData);

    const numberOfColorsNeeded = filteredData!.length;
    const newChartColors = Array.from({ length: numberOfColorsNeeded }, generateRandomColor);

    setChartColors(newChartColors);
  }, [selectedCurveCapitals]);

  const validData = selectedCurveCapitals && selectedCurveCapitals.length > 0 && selectedCurveCapitals[0].statistic;

  return (
    <React.Fragment>
      <Card className="h-100">
        <CardHeader className="border-0 align-items-center d-flex">
          <h1 className="card-title mb-0 flex-grow-1">{t('CURVA_CAPITAL')}</h1>
          <div className="d-flex gap-1">
            <button
              type="button"
              className={`btn ${period === 'daily' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('daily');
              }}
            >
              ALL
            </button>
            <button
              type="button"
              className={`btn ${period === 'week' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('week');
              }}
            >
              1W
            </button>
            <button
              type="button"
              className={`btn ${period === 'month' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('month');
              }}
            >
              1M
            </button>
            <button
              type="button"
              className={`btn ${period === 'halfyear' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('halfyear');
              }}
            >
              6M
            </button>
            <button
              type="button"
              className={`btn ${period === 'year' ? 'btn-soft-primary' : 'btn-soft-secondary'} btn-sm`}
              onClick={() => {
                onChangeChartPeriod('year');
              }}
            >
              1Y
            </button>
          </div>
        </CardHeader>
        {validData && (
          <>
            <CardHeader className="p-0 border-0 bg-light-subtle">
              <Row className="g-0 text-center">
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className={`mb-1 ${selectedCurveCapitals && selectedCurveCapitals[0].statistic.totalProfit >= 0 ? 'text-success' : 'text-danger'}`}>
                      <CountUp start={0} end={selectedCurveCapitals ? selectedCurveCapitals[0].statistic.totalProfit : 0} duration={3} decimals={2} prefix="R$" separator="," />
                    </h5>
                    <p className="text-muted mb-0">Lucro total</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-danger">
                      <CountUp start={0} decimals={2} end={selectedCurveCapitals ? selectedCurveCapitals[0].statistic.maxDrawdown : 0} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Máximo DrawnDown</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1 text-success">
                      <CountUp prefix="R$" start={0} end={selectedCurveCapitals ? selectedCurveCapitals[0].statistic.majorProfitInOneDay : 0} duration={3} decimals={2} />
                    </h5>
                    <p className="text-muted mb-0">Maior Ganho diário</p>
                  </div>
                </Col>
                <Col xs={6} sm={3}>
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className={`mb-1 text-danger`}>
                      <CountUp start={0} end={selectedCurveCapitals ? selectedCurveCapitals[0].statistic.majorLossInOneDay : 0} decimals={2} duration={3} prefix="R$" />
                    </h5>
                    <p className="text-muted mb-0">Maior Perda diária</p>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody className="p-0 pb-2">
              <div className="w-100">
                <div dir="ltr">
                  <RevenueCharts series={chartData} categories={selectedCurveCapitals[0].periods} dataColors={JSON.stringify(chartColors)} />
                </div>
              </div>
            </CardBody>
          </>
        )}
      </Card>
    </React.Fragment>
  );
};

export default Revenue;
